import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'siret' })
export class SiretPipe implements PipeTransform {
  transform(text: string, limit: number = 10) {
    if(text.length >= limit)
      return text;

    return text.toString().replace(/\d{3}(?=.)/g, '$& ');
  }
}